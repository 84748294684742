import React, { useState, useEffect } from "react"
import Layout from "../components/layout.tr"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import line_twirl from '../data/lotties/line_twirl.json'
import lines_pop from '../data/lotties/lines_pop.json'
import teamWorkLogo from '../images/team-work.svg'

import $ from "jquery";
import ClientRunner from "../components/client-runner"
import USPCards from "../components/usp-cards"
import Works from "../components/works"
import HomeHero from "../components/home-hero"
import ContactUsForm from "../components/contact-us.tr"
require('jquery-inview');
const anime = require('animejs').default;
require("@lottiefiles/lottie-player");


export default () => {

  return (
    <Layout isAnimated={true} currentPath='/' headerLight={true} footerLight={true}>
      <SEO title="Kurumsal İletişim Yazılımları" />
      <HomeHero />
      <ClientRunner />
      <section className="statement ">
        <div className="container vertical-flex">
          <div className="lottie-hands"></div>
          <img src={teamWorkLogo} loading="lazy" alt="" />
          <h2 className="home-section-2-header"><span className="text-span-3">Tüm ekibimizle</span> her zaman <span className="text-span-4">daha iyisi</span> için çalışıyoruz.</h2>
        </div>
      </section>
      <USPCards />
      <Works />
      <ContactUsForm />
    </Layout>
  );
}