import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

var translateValue = 0;

const ClientRunner = () => {
  const getClientStyle = (x) => {
    return {
      transform: `translate3d(-${x}%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
      transformStyle: "preserve-3d",
      willChange: "transform"
    }
  }
  const [clientTransformStyle, setClientTransformStyle] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      translateValue = translateValue + 0.0088;
      setClientTransformStyle(getClientStyle(translateValue));
      if (translateValue > 100) {
        translateValue = 0;
      }
    }, 0);
    return () => clearInterval(interval);
  }, []);

  return (<section className="client-runner ">
    <h4 className="heading-5">Müşterilerimiz</h4>
    <div className="client-row">
      <div className="client-inner-row" style={clientTransformStyle}>
        <div className="client-row-con">
          <div className="client-row-logo _1"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _2"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _3"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _4"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _5"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _6"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _7"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _8"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _9"></div>
        </div>
      </div>
      <div className="client-inner-row" style={clientTransformStyle}>
        <div className="client-row-con">
          <div className="client-row-logo _1"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _2"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _3"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _4"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _5"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _6"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _7"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _8"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _9"></div>
        </div>
      </div>
      <div className="client-inner-row" style={clientTransformStyle}>
        <div className="client-row-con">
          <div className="client-row-logo _1"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _2"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _3"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _4"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _5"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _6"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _7"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _8"></div>
        </div>
        <div className="client-row-con">
          <div className="client-row-logo _9"></div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ClientRunner
