import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import communicateImage from '../images/communicate.svg'
import collabImage from '../images/collab.svg'
import chatbotImage from '../images/chatbot.svg'

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;

const USPCards = () => {
  const getClientStyle = (x) => {
    return {
      transform: `translate3d(0px, -${x}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
      transformStyle: "preserve-3d",
      willChange: "transform"
    }
  }

  const [uspCard1Style, setUspCard1Style] = useState();
  const [uspCard2Style, setUspCard2Style] = useState();

  useEffect(() => {
    $(document).on("scroll", function () {
      var pixels = $(document).scrollTop();

      if (pixels > 1338 && pixels < 1883) {
        var progress = (pixels - 1338) * 0.1603;
        setUspCard1Style(getClientStyle(progress));
      }


      if (pixels > 2083 && pixels < 2546) {
        var progress = (pixels - 2083) * 0.1887;
        setUspCard2Style(getClientStyle(progress));
      }

    });

  }, []);

  return (<section id="intViewUSPCards" className="usp-cards usp " >
    <div data-w-id="261116a3-71c6-9f26-84ea-7a3d8b7b3867" className="container usp">
      <div className="usp-card _1" style={uspCard1Style}>
        <div className="usp-left-container">
          <h2 className="usp-title">Moodly<br />Kurum içi iletişim portalı</h2>
          <p className="usp-paragraph">Sharepoint ve Microsoft Teams ile uyumlu 35+ modül ile çalışanlarınıza keyifli bir dijital alan sunan, düşük maliyetli ve verimliliği artıran intranet çözümüdür.</p>
          <div className="usp-number">01</div>
        </div>
        <img src={communicateImage} alt="" className="usp-svg elastic" />
      </div>
      <div className="usp-card _2" style={uspCard2Style}>
        <div className="usp-left-container">
          <h2 className="usp-title">Moodly<br />Personel analiz platformu</h2>
          <p className="usp-paragraph">Kurum içi platformumuzdan ve üçüncü taraf uygulamalardaki verilerinizi analiz ederek personellerinizin mutluluk oranını tahminler.</p>
          <div className="usp-number">02</div>
        </div>
        <img src={collabImage} loading="lazy" alt="" className="usp-svg mood-image" />
      </div>
      <div className="usp-card _3">
        <div className="usp-left-container">
          <h2 className="usp-title">Moodly<br />Chatbot</h2>
          <p className="usp-paragraph">Microsoft Teams, Slack, Facebook, Telegram, WhatsApp gibi bir çok platformu destekleyen ChatBot platformu ile dijital çalışma alanınıza ulaşmak artık daha kolay.</p>
          <div className="usp-number">03</div>
        </div>
        <img src={chatbotImage} alt="" className="usp-svg value" />
      </div>
    </div>
  </section>
  )
}

export default USPCards
