import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import communicateImage from '../images/communicate.png'
import collabImage from '../images/collab.png'
import chatbotImage from '../images/Chatbot.png'
import worksImage from '../images/works.svg'

import $ from "jquery";
import Helper from "../utility/helper"
require('jquery-inview');
const anime = require('animejs').default;

var translateValue = 0;

const Works = () => {
  const getClientStyle = (x) => {
    return {
      transform: `translate3d(-${x}vw, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
      transformStyle: "preserve-3d",
      willChange: "transform"
    }
  }
  const [clientTransformStyle, setClientTransformStyle] = useState();
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    // Fade Up 4 (Visual Grid)
    var fadeUp4 = anime.timeline({
      loop: false,
      autoplay: false,
    });

    fadeUp4
      .add({
        targets: '.fade-in4',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 500,
        delay: (el, i) => 20 * i
      });

    let interval;
    let counterValue = 0.0388;
    let counter = counterValue;

    // Play animation when scrolled into view
    $('#inview4').on('inview', function (event, isInView) {
      var _isMobile = Helper.isMobile();
      if (_isMobile != isMobile) {
        setIsMobile(_isMobile);
      }

      if (isInView) {
        if (_isMobile) {
          const interval = setInterval(() => {
            translateValue = translateValue + counter;
            setClientTransformStyle(getClientStyle(translateValue));
            if (translateValue > 98) {
              counter = -counterValue;
            }
            else if (translateValue < 0.5) {
              counter = counterValue;
            }
          }, 0);
        }
        else {
          clearInterval(interval)
        }

        fadeUp4.play();
      } else {
      }
    });

  }, []);

  const notMobileWorkRender = () => {
    return <div className="visual-grid-container">
      <div id="inview4" className="visual-grid" style={clientTransformStyle}>
        <div className="v-flex-2">
          <div className="v-img _1 fade-in4">
            <div className="v-text">Turkcell<br />SharePoint Uygulama Geliştirme</div>
          </div>
          <div className="v-img margin-top bg-yellow fade-in4" >
            <div className="v-text">TEI<br />Tedarikçi <br />Platformu</div>
          </div>
        </div>
        <div className="v-full-height _1 fade-in4" >
          <div className="v-title-container">
            <div className="text-block-4"><span className="text-span-5">Şişecam</span><br />Camport Intranet Portal</div>
          </div>
        </div>
        <div className="v-flex-3">
          <div className="v-sub-flex-2">
            <div className="v-img2 _5 fade-in4" >
              <lottie-player
                autoplay
                loop
                mode="normal"
                src={"https://assets7.lottiefiles.com/packages/lf20_z0aek48q.json"}
              ></lottie-player>
              <div className="v-title-container">
                <div className="text-block-4"><span className="text-span-5">Enerjisa</span><br />Çağrı Merkezi Uygulamaları</div>
              </div>
            </div>
            <div className="v-img2 _1 fade-in4" >
              <lottie-player
                autoplay
                loop
                mode="normal"
                src={"https://assets3.lottiefiles.com/packages/lf20_mzodvkuz.json"}
              ></lottie-player>
              <div className="v-title-container">
                <div className="text-block-4"><span className="text-span-5">Şişecam</span><br />Denetim Platformu</div>
              </div>
            </div>
          </div>
          <div className="v-img margin-top _3 fade-in4" >
            <div className="v-title-container">
              <div className="text-block-4"><span className="text-span-5">Alişan Lojistik</span><br />Oyunlaştırma Uygulamaları</div>
            </div>
          </div>
        </div>
        <div className="v-full-height _2 fade-in4" >
          <div className="v-title-container">
            <div className="text-block-4"><span className="text-span-5">Orman Genel Müdürlüğü</span><br />Web Site</div>
          </div>
        </div>
        <div className="v-flex-2">
          <div className="v-img _2 fade-in4" >
            <div className="v-title-container">
              <div className="text-block-4"><span className="text-span-5">Alişan Lojistik</span><br />İnsan Kaynakları Uygulamaları</div>
            </div>
          </div>
          <div className="v-img margin-top bg-dark fade-in4" >
            <div className="div-block-9">
              <div className="v-text">Çimtaş<br />SharePoint Danışmanlık</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }

  const mobileWorkRender = () => {
    return <div className="visual-grid-container">
      <div id="inview4" className="visual-grid" style={clientTransformStyle}>
        <div className="v-flex-3">
          <div className="v-img _1 fade-in4">
            <div className="v-text">Turkcell<br />SharePoint Uygulama Geliştirme</div>
          </div>
          <div className="v-img margin-top bg-yellow fade-in4" >
            <div className="v-text">TEI<br />Tedarikçi Platformu</div>
          </div>
        </div>
        <div className="v-flex-3 v-full-height _1 fade-in4" >
          <div className="v-title-container">
            <div className="text-block-4"><span className="text-span-5">Şişecam</span><br />Camport Intranet Portal</div>
          </div>
        </div>
        <div className="v-flex-3">
          <div className="v-img _1 fade-in4">
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={"https://assets7.lottiefiles.com/packages/lf20_z0aek48q.json"}
            ></lottie-player>
            <div className="v-title-container">
              <div className="text-block-4"><span className="text-span-5">Enerjisa</span><br />Çağrı Merkezi Uygulamaları</div>
            </div>
          </div>
          <div className="v-img margin-top bg-yellow fade-in4" >
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={"https://assets3.lottiefiles.com/packages/lf20_mzodvkuz.json"}
            ></lottie-player>
            <div className="v-title-container">
              <div className="text-block-4"><span className="text-span-5">Şişecam</span><br />Denetim Platformu</div>
            </div>
          </div>
        </div>

        <div className="v-flex-3 v-full-height _3 fade-in4" >
          <div className="v-title-container">
            <div className="text-block-4"><span className="text-span-5">Alişan Lojistik</span><br />Oyunlaştırma Uygulamaları</div>
          </div>
        </div>

        <div className="v-full-height _2 fade-in4" >
          <div className="v-title-container">
            <div className="text-block-4"><span className="text-span-5">Orman Genel Müdürlüğü</span><br />Web Site</div>
          </div>
        </div>
        <div className="v-flex-2">
          <div className="v-img _2 fade-in4" >
            <div className="v-title-container">
              <div className="text-block-4"><span className="text-span-5">Alişan Lojistik</span><br />İnsan Kaynakları Uygulamaları</div>
            </div>
          </div>
          <div className="v-img margin-top bg-dark fade-in4" >
            <div className="div-block-9">
              <div className="v-text">Çimtaş<br />SharePoint Danışmanlık</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }

  return (<section className="a-global-network ">
    <div className="title-container">
      <img src={worksImage} loading="lazy" alt="" />
      <h2>İşlerimiz</h2>
      <p className="paragraph-global">Tamamlamış olduğumuz bazı projelerimiz</p>
    </div>
    {isMobile ? mobileWorkRender() : notMobileWorkRender()}
  </section>)
}

export default Works
