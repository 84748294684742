import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import shape_bg from '../images/shape_bg.svg'

import $ from "jquery";
require('jquery-inview');
const anime = require('animejs').default;

var translateValue = 0;


const HomeHero = () => {

  const getClientStyle = (x) => {
    return {
      transform: `translate3d(0%, ${x}%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
      transformStyle: "preserve-3d",
      willChange: "transform"
    }
  }
  const [clientTransformStyle, setClientTransformStyle] = useState();


  useEffect(() => {
    // letterFadeUp 3 (Client boxes)
    var letterFadeUp = anime.timeline({
      loop: false,
      autoplay: false,
    });

    letterFadeUp
      .add({
        targets: '.tricksword',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0.7, 1],
        easing: "easeOutExpo",
        duration: 1600,
        delay: (el, i) => 50 + 80 * i
      });

    letterFadeUp.play();

    // letterFadeUp 3 (Client boxes)
    var videoFadeUp = anime.timeline({
      loop: false,
      autoplay: false,
    });

    videoFadeUp
      .add({
        targets: '.w-background-video',
        translateY: [50, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1600,
        delay: (el, i) => 50 + 80 * i
      });

    videoFadeUp.play();

    let counterValue = 0.00388;
    let counter = counterValue;

    const interval = setInterval(() => {
      translateValue = translateValue + counter;
      setClientTransformStyle(getClientStyle(translateValue));
      if (translateValue > 10) {
        counter = -counterValue;
      }
      else if (translateValue < -1) {
        counter = counterValue;
      }
    }, 0);
    return () => clearInterval(interval);

  }, []);

  return (<section className="hero home-hero ">
    <header className="container hero-flex">
      <div className="div-block-3">
        <h1 className="heading-4 fade-up tricks">
          <span className="tricksword text-span-2"><span className="tricksword"><span className="letter">Dijital dönüşüm</span></span></span>
          <span className="tricksword" >
            <span className="letter">hikayenizi </span>
          </span>
          <span className="tricksword" >
            <span className="letter">birlikte yazalım</span>
          </span>
        </h1>
        <div className="div-block">
          <a href="/iletisim" className="btn big green w-inline-block">
            <div className="btn-text">Daha Fazla Bilgi</div>
            <div className="btn-cover"></div></a><p className="paragraph">Uygun maliyetli, kullanıcı dostu ürünlerimiz hakkında daha fazla bilgi için tıklayınız</p></div>
      </div>
      <div className="home-top">
        <img src={shape_bg} loading="lazy" className="shape-beige" style={clientTransformStyle} />
        <div className="div-block-2">
          <div className="lottie-twirl" >
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={"https://assets7.lottiefiles.com/packages/lf20_vuo6kupa.json"}
            ></lottie-player>
          </div>
          <div className="lottie-lines" >
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={"https://assets3.lottiefiles.com/packages/lf20_bqqjjlla.json"}
            ></lottie-player>
          </div>
          <div className="video-big">
            <div className="video-big-content w-background-video w-background-video-atom">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src={"https://assets2.lottiefiles.com/packages/lf20_fv7gom7p.json"}
              ></lottie-player>
            </div>
          </div>
          <div className="video-top">
            <div className="video-top-content w-background-video w-background-video-atom">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src={"https://assets4.lottiefiles.com/packages/lf20_zq0fr462.json"}
              ></lottie-player>
            </div>
          </div>
          <div className="video-medium">
            <div className="video-medium-content w-background-video w-background-video-atom">
              <lottie-player
                autoplay
                loop
                mode="normal"
                src={"https://assets8.lottiefiles.com/packages/lf20_ruf4mhbv.json"}
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>
    </header>
  </section>
  )
}

export default HomeHero
